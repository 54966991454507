//Buttons

.btn {
  margin-right: 5px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
  &.btn-sm {
    width: 35px;
    height: 35px;
    padding: 8px 10px;
    font-size: 14px;
  }
  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 14px 15px;
    font-size: 18px;
    line-height: 23px;
  }
  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 14px 15px;
    font-size: 24px;
  }
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.btn-rounded {
  border-radius: 60px;
  padding: 7px 18px;

  &.btn-lg {
    padding: 0.75rem 1.5rem;
  }
  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 12px;
  }
  &.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 10px;
  }
  &.btn-md {
    padding: 12px 35px;
    font-size: 16px;
  }
}

.btn-outline-default {
  color: $primary;
  border-color: #e6e6e6;
  &:hover{
    color: $white;
    background-color: $primary;
  }
}

.btn-primary,
.btn-default,
.btn-warning,
.btn-outline-primary:hover,
.btn-outline-warning:hover {
  color: $white;
  &:hover {
    color: $white;
  }
}

.btn-facebook {
  color: $white;
  background-color: #3b5998;
  &:hover {
    color: $white;
  }
}

.btn-twitter {
  color: $white;
  background-color: #55acee;
  &:hover {
    color: $white;
  }
}

.btn-linkedin {
  color: $white;
  background-color: #007bb6;
  &:hover {
    color: $white;
  }
}

.btn-dribbble {
  color: $white;
  background-color: #ea4c89;
  &:hover {
    color: $white;
  }
}

.btn-googleplus {
  color: $white;
  background-color: #dd4b39;
  &:hover {
    color: $white;
  }
}

.btn-instagram {
  color: $white;
  background-color: #3f729b;
}

.btn-pinterest {
  color: $white;
  background-color: #cb2027;
}

.btn-dropbox {
  color: $white;
  background-color: #007ee5;
}

.btn-flickr {
  color: $white;
  background-color: #ff0084;
}

.btn-tumblr {
  color: $white;
  background-color: #32506d;
}

.btn-skype {
  color: $white;
  background-color: #00aff0;
}

.btn-youtube {
  color: $white;
  background-color: #bb0000;
}

.btn-github {
  color: $white;
  background-color: #171515;
}

.button-group {
  .btn {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}
.no-button-group .btn {
  margin-bottom: 5px;
  margin-right: 0px;
}

.btn .text-active {
  display: none;
}

.btn.active .text-active {
  display: inline-block;
}

.btn.active .text {
  display: none;
}

.btn-icon {
  background: transparent;
  border: 0;
  height: 30px;
  margin-right: 0;
  padding: 4px;
  width: 30px;

  i.fa-eye {
    color: #29afda;    
  }

  i.fa-copy {
    color: #03cbb9;
  }

  // &:hover {
  //   background: #536dc4;

  //   i {
  //     color: #FFF;    
  //   }
  // }
}

.btn-show-more {
  padding: 0;
  border: 0;

  &:hover, &:active {
    background-color: transparent !important;
    color: #00afda !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}