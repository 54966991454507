// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
@import './variable.scss';

#toast-container > div {
  opacity: 1;

  &.toast-warning {
    background-color: $yellow;
  }
  &.toast-success {
    background-color: $green;
  }
  &.toast-error {
    background-color: $red;
  }
  &.toast-info {
    background-color: $themecolor;
  }
}

.island-icon {
  background-image: url("src/assets/images/icons/island.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  object-fit: contain;
  width: 34px;
  height: 25px;
}

.paypal-icon {
  background-image: url("src/assets/images/icons/paypal.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  object-fit: contain;
  width: 70px;
  height: 66px;
}

.mercadopago-icon {
  background-image: url("src/assets/images/icons/mercadopago.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  object-fit: contain;
  width: 70px;
  height: 66px;
}

.oxxo-icon {
  background-image: url("src/assets/images/icons/oxxo.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  object-fit: contain;
  width: 70px;
  height: 66px;
}

.ebanx-icon {
  background-image: url("src/assets/images/icons/ebanx.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  object-fit: contain;
  width: 70px;
  height: 66px;
}

.amazon-pay-icon {
  background-image: url("src/assets/images/icons/amazon.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  object-fit: contain;
  width: 70px;
  height: 66px;
}

.disabled-link {
  cursor: none;
  pointer-events: none; 
}
